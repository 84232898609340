import { instance } from '@/utils/http.js'

//学习中心 自由选题》根据科目id选择地区
export const getPaperDirList = (params) => {
  return instance({
    url: '/api/v1/public/paper_districts',
    method: 'get',
    params
  })
}

//学习中心 自由选题》题目难度
export const getQuestionDifficulty = () => {
  return instance({
    url: '/api/v1/public/get_question_difficulty',
    method: 'get'
  })
}
//学习中心 自由选题》根据科目id和地区获取题目来源
export const getPaperSource = (params) => {
  return instance({
    url: '/api/v1/public/paper_source_sd',
    method: 'get',
    params
  })
}
//学习中心 自由选题》根据科目id获取题目类型和题目类别
export const getTypeCategory = (params) => {
  return instance({
    url: '/api/v1/public/paper_type_category',
    method: 'get',
    params
  })
}
//学习中心 自由选题》根据科目id获取章节列表
export const getChapterList = (params) => {
  return instance({
    url: '/api/v1/public/get_chapter',
    method: 'get',
    params
  })
}

////学习中心 自由选题》提交表单获取试卷id
export const getFreeTrain = (data) => {
  return instance({
    url: '/api/v1/free/free_train',
    method: 'post',
    data
  })
}
