export const rules = {
  paper_district: [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  sourceId: [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  startYear: [
    { required: true, message: '请输入', trigger: 'change' },
  ],
  endYear: [
    { required: true, message: '请输入', trigger: 'change' },
  ],
  number: [
    { required: true, message: '请输入', trigger: 'change' },
  ],
  type: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
  category: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
  point: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
  point2: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
  point3: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
  difficulty: [
    { required: false, message: '请选择', trigger: 'change' },
  ],
}
export const years = [
  2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029
]
export const formItemLayout = {
  labelCol: {

    span: 6
  },
  wrapperCol: {

    span: 17
  },
};